<template>
  <base-dialog v-model="dialog" max-width="840" title="Delete .CSV">
    <v-container>
      <form enctype="multipart/form-data" class="ma-4">
        <input ref="fileUpload" accept=".csv" type="file" @change="onFileChange" />
      </form>
      <v-divider />
      <v-alert v-if="readedFromCsv === 0" type="error" outlined dense class="ma-4">Não foi possível processar os contatos importados</v-alert>
      <v-card-title v-if="readedFromCsv">Total de contatos processados: {{ readedFromCsv }}</v-card-title>
      <v-row no-gutters>
        <v-btn v-if="readedFromCsv" :disabled="!readedFromCsv" class="ma-2" color="error" @click="deleteFromCsv"
          ><v-icon class="mr-2">mdi-alert-circle</v-icon> Excluir contatos processados</v-btn
        >
        <v-btn v-if="readedFromCsv" class="ma-2" color="primary" @click="preview = true"
          ><v-icon class="mr-2">mdi-file-find</v-icon> Prévia de contatos processados</v-btn
        >
      </v-row>
    </v-container>
    <!-- import preview -->
    <show-contacts v-if="contacts" v-model="preview" title="Prévia dos contatos importados">
      <v-chip v-for="contact in contacts" :key="contact.index" class="ma-1">{{ contact }}</v-chip>
    </show-contacts>
  </base-dialog>
</template>

<script>
import { MUTATION_DELETE_CONTACTS_FROM_CSV } from '@/modules/accounts/graphql'
import ShowContacts from './ShowContacts.vue'
import BaseDialog from '../../../../components/atomic-components/atoms/BaseDialog.vue'

export default {
  components: {
    ShowContacts,
    BaseDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    accountId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      readedFromCsv: null,
      contacts: [],
      preview: false,
      dialog: this.value
    }
  },
  watch: {
    value() {
      this.dialog = this.value
    },
    dialog() {
      this.$emit('input', this.dialog)
    }
  },
  methods: {
    async onFileChange(e) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        let readed = e.target.result
          .toString()
          .split('\r\n')
          .filter(n => n)
        if (readed.length <= 1) {
          readed = e.target.result
            .toString()
            .split('\n')
            .filter(n => n)
        }
        this.readedFromCsv = readed.length || 0
        this.contacts = readed || []
      }
      reader.readAsText(file)
    },
    close() {
      this.$emit('close')
      this.readedFromCsv = null
      this.contacts = []
      this.$refs.fileUpload.value = null
    },
    async deleteFromCsv() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_DELETE_CONTACTS_FROM_CSV,
          variables: {
            id: this.accountId,
            contacts: this.contacts
          }
        })
        this.$snackbar({ message: 'Contatos removidos', snackbarColor: '#2E7D32' })
        setTimeout(() => {
          this.$router.go()
        }, 200)
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao remover contatos ou contatos não encontrados', snackbarColor: '#F44336' })
      }
    }
  }
}
</script>
